<template>
  <div>

  </div>
</template>

<script>
import { getUrlParam } from '@/utils/getUrlQeury';
import { Login } from '@/api/login';
export default {
  name:'',
  components: {

  },
  props: {

  },
  data() {
    return {
      code: ''
    }
  },
  computed: {

  },
  watch: {

  },

  created() { 
    if (!getUrlParam('code')) {
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6c6408fd5591d455&redirect_uri=https%3a%2f%2fduojietai.com&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
    } else {
      this.code = this.$route.query.code || getUrlParam('code');
      Login(this.code).then(res => {
        localStorage.setItem('login', JSON.stringify(res.data))
        localStorage.setItem('carToken', res.data.authorization)
        localStorage.setItem('language', 'anduo')
        // if (localStorage.getItem('language')) {
        //   this.$router.push('/')
        // } else {
        //   this.$router.push('/languageChoose')
        // }
        this.$router.push('/')
      })
    }   
    // if (localStorage.getItem('language')) {
    //   localStorage.setItem('carToken', 'bzExYTM1dzRqRm96dUoyN1ZQWmdmeTIwZ2d5NA==')
    //   this.$router.push('/')
    // } else {
    //   localStorage.setItem('carToken', 'bzExYTM1dzRqRm96dUoyN1ZQWmdmeTIwZ2d5NA==')
    //   this.$router.push('/languageChoose')
    // }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
